<template>
  <div class="items-center hidden md:flex">
    <Flicking
      :options="optionsCarruselProductosDesktop"
      :plugins="pluginsCarruselProductosDesktop"
      class="mx-auto md:pb-16 lg:pb-20"
      ref="carruselProductosDesktop"
      @ready="reAgruparProductos"
      @after-resize="reAgruparProductos"
    >
      <div
        v-for="(productos, key) in productosAgrupados"
        :key="key"
        class="flex gap-x-4 xl:gap-x-6 px-2 xl:px-3 w-full"
      >
        <TarjetaProducto
          class="border-gray-100 bg-white border px-2.5 py-2 md:min-w-48 lg:min-w-44 lg:max-w-64"
          :class="[`w-1/${productosAgrupados[0].length}`]"
          truncateName
          v-for="producto in productos"
          :producto="producto"
          :loading="loading"
        />
      </div>
      <template #viewport>
        <Paginador
          class="bottom-1 lg:bottom-5 hidden md:flex"
          :carrusel="carruselProductosDesktop"
          :nombre-paginador="nombrePaginadorcarruselProductosDesktop"
          :background="false"
        />
      </template>
    </Flicking>
  </div>
  <div class="items-center md:hidden">
    <Flicking
      :options="optionsCarruselProductosMobile"
      :plugins="pluginsCarruselProductosMobile"
      class="mx-auto md:pb-10"
      ref="carruselProductosMobile"
      @ready="reAgruparProductos"
      @after-resize="reAgruparProductos"
    >
      <TarjetaProducto
        class="border-gray-100 w-48 bg-white border px-2.5 py-2 mx-1"
        truncateName
        v-for="(producto, key) in productos"
        :key="key"
        :producto="producto"
        :loading="loading"
      />
      <template #viewport>
        <Paginador
          class="hidden"
          :carrusel="carruselProductosMobile"
          :nombre-paginador="nombrePaginadorcarruselProductosMobile"
          :background="false"
        />
      </template>
    </Flicking>
  </div>
</template>
<script setup lang="ts">
  import Flicking from "@egjs/vue3-flicking";
  import TarjetaProducto from "@components/producto/TarjetaProducto.vue";
  import { ref } from "@vue/runtime-core";
  import type { Producto } from "@lib/interfaces";
  import Paginador from "@components/ui/Paginador.vue";
  import { plugins } from "@lib/bullets";

  interface Props {
    productos: Readonly<Producto[]>;
    loading?: boolean;
    parent?: string;
    carruselNombre?: string;
    categoria?: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    loading: false,
  });

  const carruselProductosDesktop = ref<Flicking>();
  const carruselProductosMobile = ref<Flicking>();

  const nombrePaginadorcarruselProductosDesktop =
    "paginador-carrusel-productos-home-desktop";
  const nombrePaginadorcarruselProductosMobile =
    "paginador-carrusel-productos-home-mobile";

  const optionsCarruselProductosMobile = {
    align: "center",
    resizeDebounce: 120,
    preventDefaultOnDrag: true,
    defaultIndex: 1,
  };

  const pluginsCarruselProductosMobile = plugins(
    nombrePaginadorcarruselProductosMobile,
    false,
    true,
  );

  const optionsCarruselProductosDesktop = {
    align: "center",
    resizeDebounce: 120,
    preventDefaultOnDrag: true,
    defaultIndex: 0,
  };

  const pluginsCarruselProductosDesktop = plugins(
    nombrePaginadorcarruselProductosDesktop,
    false,
    false,
  );

  let cantidadAgrupada = ref(5);

  const productosAgrupados = ref<Producto[][]>([]);
  for (let i = 0; i < props.productos.length; i += cantidadAgrupada.value) {
    productosAgrupados.value.push(
      props.productos.slice(i, i + cantidadAgrupada.value),
    );
  }

  const reAgruparProductos = (): void => {
    if (window.innerWidth > 1360) {
      cantidadAgrupada.value = 5;
    } else if (window.innerWidth > 920) {
      cantidadAgrupada.value = 4;
    } else {
      cantidadAgrupada.value = 3;
    }

    const productosAgrupadosNuevos = [];
    for (let i = 0; i < props.productos.length; i += cantidadAgrupada.value) {
      productosAgrupadosNuevos.push(
        props.productos.slice(i, i + cantidadAgrupada.value),
      );
    }
    productosAgrupados.value = productosAgrupadosNuevos;
  };

  const reiniciarCarrusel = async (): Promise<void> => {
    await carruselProductosDesktop.value?.moveTo(0);
    if (props.productos.length > 2) {
      await carruselProductosMobile.value?.moveTo(1);
    } else {
      await carruselProductosMobile.value?.moveTo(0);
    }
    reAgruparProductos();
  };

  defineExpose({
    reiniciarCarrusel,
  });
</script>
