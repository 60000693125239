<template>
  <svg
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="icono de fuego"
  >
    <path
      d="M16.0289 6.10283C17.7537 6.8736 19.1619 8.21289 20.0181 9.89697C20.8743 11.5811 21.1268 13.5079 20.7332 15.3557C20.3397 17.2035 19.324 18.8604 17.8559 20.0494C16.3878 21.2385 14.5561 21.8878 12.6669 21.8888C11.0509 21.8888 9.47051 21.4141 8.12191 20.5238C6.7733 19.6334 5.71587 18.3667 5.08087 16.8807C4.44587 15.3947 4.26126 13.7549 4.54997 12.1649C4.83868 10.5749 5.58799 9.10472 6.70488 7.93683C7.47454 9.01156 8.49014 9.88658 9.66688 10.4888C9.6875 9.16745 9.99941 7.8669 10.5804 6.67992C11.1613 5.49293 11.997 4.44875 13.0279 3.62183C13.8146 4.67702 14.8435 5.52759 16.0279 6.10183L16.0289 6.10283Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.6678 18.8891C13.6191 18.8888 14.5348 18.5268 15.2293 17.8766C15.9238 17.2264 16.3452 16.3365 16.4081 15.3872C16.4711 14.4379 16.1708 13.5001 15.5682 12.7639C14.9657 12.0277 14.1058 11.5481 13.1628 11.4221C12.1303 12.3459 11.45 13.5991 11.2378 14.9681C10.4674 14.7797 9.74234 14.4397 9.10476 13.9681C8.91961 14.5314 8.87066 15.1305 8.96193 15.7164C9.0532 16.3022 9.28209 16.8581 9.62981 17.3383C9.97753 17.8186 10.4342 18.2095 10.9623 18.4791C11.4904 18.7487 12.0748 18.8892 12.6678 18.8891Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script setup lang="ts">
  interface Props {
    color?: string;
  }

  withDefaults(defineProps<Props>(), {
    color: "white",
  });
</script>
