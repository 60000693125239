<template>
  <article
    class="flex w-full flex-col justify-center gap-8 md:gap-5 lg:gap-10 md:bg-gray-50 rounded-lg py-5 lg:pt-12 lg:pb-0"
  >
    <div class="px-5 lg:px-14">
      <Flicking v-if="!isServer" :options="options" ref="carruselTabsHome">
        <div
          v-for="(tab, key) in tabs"
          :key="key"
          class="flex flex-col gap-y-2 mx-2 md:mx-3 lg:mx-4 w-44 md:w-auto md:min-w-32 md:max-w-52 lg:min-w-72"
          :class="[`w-1/${tabs.length}`]"
        >
          <button
            type="button"
            @click="onTabClick(tab.nombre, key)"
            class="flex justify-center items-center rounded-md px-3 py-2 h-8 md:h-10 lg:h-16 text-xs font-medium shadow-sm md:text-sm md:hover:bg-green-700 md:hover:text-white transition-colors duration-200 lg:text-xl"
            :class="[
              tabSeleccionado === tab.nombre
                ? 'bg-green-700 text-white'
                : 'bg-lime-100 text-green-500',
            ]"
            @mouseover="
              () => (tab.nombre === 'ofertas' ? (mouseOver = true) : null)
            "
            @mouseleave="
              () => (tab.nombre === 'ofertas' ? (mouseOver = false) : null)
            "
          >
            <FuegoIcon
              class="mr-2 md:2.5 lg:mr-4 w-4 h-4 md:w-5 md:h-5 lg:h-9 lg:w-9"
              v-if="tab.nombre === 'ofertas'"
              :color="
                tabSeleccionado === tab.nombre || mouseOver
                  ? 'white'
                  : '#297E36'
              "
            />
            <span class="uppercase">
              {{ tab.texto }}
            </span>
          </button>
          <div
            class="h-1.5 w-auto rounded-full bg-green-700"
            :class="tab.nombre !== tabSeleccionado ? 'hidden' : 'flex'"
          ></div>
        </div>
      </Flicking>
    </div>

    <div v-if="!isServer" id="carrusel-productos" class="px-6 lg:px-20">
      <CarruselProductos
        :loading="loading"
        :productos="productosFiltrados"
        parent="carrusel-productos"
        ref="carrusel_productos_ref"
      />
    </div>

    <div
      class="min-h-12 lg:min-h-16 bg-gray-100 mx-6 -mt-8 rounded-lg md:-mt-5 lg:-mt-10 mb-4"
      v-if="isServer"
    ></div>
    <div
      class="min-h-96 bg-gray-100 mx-6 rounded-lg mb-4"
      v-if="isServer"
    ></div>
    <div
      class="min-h-8 bg-gray-100 mx-auto rounded-lg w-64 mb-6 hidden md:block"
      v-if="isServer"
    ></div>
  </article>
</template>

<script setup lang="ts">
  import CarruselProductos from "./CarruselProductos.vue";
  import { ref, computed, watch, onMounted } from "@vue/runtime-core";
  import Flicking from "@egjs/vue3-flicking";
  import {
    getProductosPopulares,
    getProductosTabCarrusel,
    getProductosUltimoPedidoUser,
  } from "@api/client/productos";
  import { getCookie } from "@lib/cookies";
  import type { Producto, User } from "@lib/interfaces";
  import { ventanaActiva } from "@stores/despacho";
  import FuegoIcon from "@components/iconos/FuegoIcon.vue";
  import { actualizarPrecioOfertaStockDisponibleProductos } from "@stores/app";
  import { useStore } from "@nanostores/vue";
  import { user as userStore } from "@stores/user";

  interface Props {
    productos: Producto[];
    user: User | null;
  }
  const props = defineProps<Props>();

  const carruselTabsHome = ref<Flicking>();
  const mouseOver = ref<boolean>(false);
  const loading = ref<boolean>(false);
  const $ventanaActiva = useStore(ventanaActiva);
  const isServer = ref(true);

  const carrusel_productos_ref = ref<{
    reiniciarCarrusel: () => Promise<void>;
  }>();
  const tabSeleccionado = ref("recientes");
  const ultimoPedidoValido = ref<boolean>(true);
  const tabs = computed(() => {
    const tabs = [
      { nombre: "ofertas", texto: "Ofertas exclusivas" },
      { nombre: "populares", texto: "Populares" },
      { nombre: "recientes", texto: "Recién llegados" },
    ];
    if (userTienePedidos.value && ultimoPedidoValido.value) {
      tabs.splice(1, 0, {
        nombre: "pedido-anterior",
        texto: "Pedido anterior",
      });
    }
    return tabs;
  });

  const userTienePedidos = computed(() => {
    let user = null;
    if (useStore(userStore).value != null) {
      user = useStore(userStore).value;
    } else {
      user = props.user;
    }

    return user ? user.cantidad_pedidos > 0 : false;
  });

  const options = {
    defaultIndex: tabs.value.findIndex((tab) => tab.nombre === "recientes"),
    resizeDebounce: 120,
    bound: true,
    preventDefaultOnDrag: true,
    align: "center",
  };

  const productos_carrusel = ref<Producto[]>([]);
  productos_carrusel.value = props.productos;

  const actualizarProductosCarrusel = async (productos: Producto[]) => {
    productos_carrusel.value = productos;
  };

  const obtenerProductosPorTab = async (tab: string) => {
    const cookies = document.cookie;
    const bodegaId = getCookie("bodega", cookies);
    const ventana = getCookie("ventana", cookies);
    let productos = null;

    if (tab === "populares") {
      productos = await getProductosPopulares(bodegaId, ventana);
    } else if (tab === "pedido-anterior") {
      ({ productos } = await getProductosUltimoPedidoUser(
        bodegaId,
        ventana,
        getCookie("token", cookies),
      ));
    } else {
      ({ productos } = await getProductosTabCarrusel(bodegaId, tab, ventana));
    }

    return productos;
  };

  const onTabClick = async (tab: string, index: number) => {
    if (tabSeleccionado.value == tab) {
      return;
    }
    loading.value = true;
    const productos = await obtenerProductosPorTab(tab);
    if (productos) {
      await actualizarProductosCarrusel(productos);
    }
    tabSeleccionado.value = tab;
    await carruselTabsHome.value?.moveTo(index);
    await carrusel_productos_ref.value?.reiniciarCarrusel();
    loading.value = false;
  };

  const tabsConCondicionDeStock = ["ofertas", "recientes"];
  // Filtrar productos con stock disponible en caso de que pertenezca a ofertas o recientes
  const validacionesProducto = (producto: Producto) => {
    return tabsConCondicionDeStock.includes(tabSeleccionado.value)
      ? producto.stock_disponible > 0
      : true;
  };

  const productosFiltrados = computed(() => {
    return productos_carrusel.value.filter((producto) => {
      return (
        (producto.activo_bodega || producto.activo_cd) &&
        validacionesProducto(producto)
      );
    });
  });

  watch($ventanaActiva, async () => {
    actualizarPrecioOfertaStockDisponibleProductos(productos_carrusel.value);
  });

  const validarUltimoPedido = async () => {
    let valido = false;
    const cookies: string = document.cookie;
    const bodegaId: string | undefined = getCookie("bodega", cookies);
    const ventana: string | undefined = getCookie("ventana", cookies);

    const respuesta = await getProductosUltimoPedidoUser(
      bodegaId,
      ventana,
      getCookie("token", cookies),
    );

    if (respuesta.estado == "ok") {
      valido = respuesta.productos.some((producto) => producto.activo_bodega);
    }
    ultimoPedidoValido.value = valido;
  };

  onMounted(() => {
    isServer.value = false;
    if (userTienePedidos.value) validarUltimoPedido();
  });
</script>
